<style scoped></style>

<template>
  <div style="display: flex; flex-direction: column;background-color: #FFF;">
    <fm-title title-text="工资发布" @clickTitleMenu="clickTitleMenu" :title-menus="titleMenus" />
    <i-input style="width: 300px; margin: 0 10px;" placeholder="请输入名字或代码搜索" search @on-search="key => searchKey = key" @on-clear="searchKey = null" clearable>
      <span slot="prepend">搜索</span>
    </i-input>
    <div class="data-list" ref="content" style="margin-top: 10px; flex: 1; height: 0;">
      <Table ref="table" :loading="loading.load" :data="pageDataList" :columns="columns" :height="tableHeight">
        <template slot-scope="{ row }" slot="action">
          <Button v-if="$authFunsProxyNoRoute['performance.wages.manage.index-editDetail']" icon="ios-brush" type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
          <Poptip v-if="$authFunsProxyNoRoute['performance.wages.manage.index-delDetail']" transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>
    <div style="padding: 10px;background-color: #FFF;">
      <Page @on-change="val => page.num = val" :total="searchDataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer draggable :mask-closable="false" mask transfer title="工资修改" width="40" placement="right" closable v-model="status.form">
      <DrawerAction v-if="chooseData" :loading="loading.submit" @confirm="submitEdit" @cancel="status.form = false">
        <Form :label-width="80">
          <FormItem v-for="item in formItems" :key="item.key" :label="item.label">
            <Input type="number" v-model="chooseData[item.key]" :placeholder="'请输入' + item.label" />
          </FormItem>
        </Form>
      </DrawerAction>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'
import DrawerAction from '../../../../components/drawer/action.vue'

export default {
  components: { DrawerAction },
  data () {
    return {
      dataList: [],
      loading: {
        load: false,
        submit: false
      },
      page: {
        size: 30,
        num: 1
      },
      searchKey: null,
      tableHeight: null,
      configList: [],
      chooseData: null,
      status: {
        form: false
      }
    }
  },
  computed: {
    searchDataList () {
      const searchKey = this.searchKey
      if (searchKey) {
        return this.dataList.filter(v => String(v.code).indexOf(searchKey) > -1 || String(v.wageWorkerName).indexOf(searchKey) > -1)
      }
      return this.dataList
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.searchDataList.slice(start, end)
    },
    titleMenus () {
      return [
        { key: 'back', label: '返回' },
        { key: 'reload', label: '刷新', fun: 'getDetail' },
        { key: 'clear', label: '清除数据', fun: 'switchStatus' },
        { key: 'end', label: '发布', fun: 'switchStatus' }
      ].filter(v => {
        return !v.fun || this.$authFunsProxyNoRoute['performance.wages.manage.index-' + v.fun]
      })
    },
    columns () {
      const configList = this.configList
      return [
        { key: 'code', title: '代码', width: 100, fixed: 'left' },
        { key: 'wageWorkerName', title: '姓名', width: 100, fixed: 'left' },
        ...configList.map(v => {
          return { key: v.valueKey, title: v.valueLabel, minWidth: 100 }
        }),
        { key: 'action', title: '操作', slot: 'action', width: 180, fixed: 'right' }
      ]
    },
    formItems () {
      return this.configList.filter(v => v.canEdit).map(item => {
        return {
          key: item.valueKey,
          label: item.valueLabel
        }
      })
    }
  },
  methods: {
    async clickTitleMenu (key) {
      if (key === 'back') {
        this.$router.back()
      } else if (key === 'clear' && await this.$confirm({ title: '确定清除吗？' })) {
        await request('/performance/wage_month/status/' + this.$route.params.monthData.id, 'post', {
          status: 'wait'
        })
        this.$router.replace({
          name: 'performance.wages.manage.detail',
          query: { id: this.$route.params.monthData.id }
        })
      } else if (key === 'end' && await this.$confirm({ title: '确定发布吗？' })) {
        await request('/performance/wage_month/status/' + this.$route.params.monthData.id, 'post', {
          status: 'end'
        })
        this.$router.replace({
          name: 'performance.wages.manage.detail',
          query: { id: this.$route.params.monthData.id }
        })
      } else if (key === 'reload') {
        this.loadData()
      }
    },
    async submitEdit () {
      this.loading.submit = true
      try {
        await request('/performance/wage_month_worker', 'post', this.chooseData)
        this.$Message.success('已修改')
        this.loadData()
        this.status.form = false
      } catch (e) {
        this.$Modal.error({ title: '修改失败', content: e.message })
      }
      this.loading.submit = false
    },
    itemEdit (row) {
      this.chooseData = JSON.parse(JSON.stringify(row))
      this.status.form = true
    },
    async itemDel (row) {
      try {
        await request('/performance/wage_month_worker/' + row.id, 'delete')
        this.loadData()
        this.$Message.success('已删除')
      } catch (e) {
        this.$Modal.error({
          title: '删除失败', content: e.message
        })
      }
    },
    async loadData () {
      if (!this.$authFunsProxyNoRoute['performance.wages.manage.index-getDetail']) {
        return
      }
      this.loading.load = true
      try {
        const configList = await request('/performance/wage_month/worker_config/' + this.$route.params.monthData.id, 'get')
        this.configList = configList.sort((a, b) => {
          if (a.sortNum === null) {
            return 1
          } else {
            return a.sortNum - b.sortNum
          }
        })
        this.dataList = await request('/performance/wage_month_worker', 'get', {
          wageMonthId: this.$route.params.monthData.id
        })
      } catch (e) {
        this.$Modal.error({
          title: '加载失败', content: e.message, onOk: this.$router.back()
        })
      }
      this.loading.load = false
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
    },
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
    this.loadData()
  },
  destroyed () {
    this.dataList = []
    window.removeEventListener('resize', this.resize)
  }
}
</script>
